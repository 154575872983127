"use client"
import { useRouter } from 'next/navigation';
import { useEffect, useState, ReactNode } from 'react';
import { useWeb3Authentication } from '@/context/web3AuthContext';
import { usePathname } from "next/navigation";
import { toast } from "react-toastify";
const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const { isLoading, userData, reconnect, web3Auth, isLoggedIn } = useWeb3Authentication();
  const router = useRouter();
  const [isChecking, setIsChecking] = useState(true);
  const pathname = usePathname();

  useEffect(() => {
    const checkAuth = async () => {
      if (pathname === '/' || pathname === '/accept-invitation') {
        return;
      }

      if (!isLoading && !userData && web3Auth && isLoggedIn) {
        const reconnectedData = await reconnect();
        if (!reconnectedData) {
          if (userData.access_level === "none") {
            setTimeout(() => {
              router.replace('/anon');
            }, 100);
          } else {
            setTimeout(() => {
              router.replace('/dashboard');
            }, 100);
          }
          return;
        }
      }

      if (!isLoggedIn) {
        router.replace('/');
        return;
      }

      // Check access level and sandbox status
      if (userData) {
        const { access_level, sandbox_expiry } = userData;
        
        if (access_level === 'sandbox') {
          // Check if the current route is allowed for sandbox users
          toast.info(`Checking sandbox access for ${userData.name}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          if (!isSandboxAllowed(pathname)) {
            router.replace('/sandbox-dashboard');
            return;
          }
        }
      }

      setIsChecking(false);
    };

    checkAuth();
  }, [isLoading, userData, pathname]);


  return children;
};

function isSandboxAllowed(path: string) {
  const sandboxAllowedPaths = ['/sandbox-dashboard', '/sandbox-analytics', '/sandbox-events'];
  return sandboxAllowedPaths.some(allowedPath => path.startsWith(allowedPath));
}

export default ProtectedRoute;